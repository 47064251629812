import React from "react";
import ReactGA from "react-ga";
import twitterIcon from "../assets/twitter_icon.png";
import lineIcon from "../assets/line_icon.png";
import facebookIcon from "../assets/facebook_icon.png";
import { isProduction } from "../constants";

const clickSareButton = (mediaName, url) => {
  if (isProduction) {
    ReactGA.event({
      category: "share",
      action: `to: ${mediaName}`,
      value: 1
    });
  }
  window.open(
    url,
    "SNS_window",
    "width=600, height=500, menubar=no, toolbar=no, scrollbars=yes"
  );
};

const ShareButtons = ({ shareUrl, shareText }) => {
  const br = "%0a";
  const hashStr = "%23";
  const escapeUrl = shareUrl.replace("#", hashStr);
  const teitterHashTag = "%23現実逃避映画診断 %23numaflow";
  const twitterText = [shareText, teitterHashTag, ""].join(br);
  const shareList = [
    {
      type: "twitter",
      iconSrc: twitterIcon,
      url: `https://twitter.com/share?text=${twitterText}&url=${escapeUrl}`
    },
    {
      type: "line",
      iconSrc: lineIcon,
      url: `https://social-plugins.line.me/lineit/share?url=${escapeUrl}`
    },
    {
      type: "facebook",
      iconSrc: facebookIcon,
      url: `https://www.facebook.com/dialog/share?app_id=453582818640323&&href=${escapeUrl}`
    }
  ];
  return (
    <>
      <div className="share-container">
        {shareList.map((share, idx) => (
          <button
            className="share-button"
            key={idx}
            onClick={() => clickSareButton(share.type, share.url)}
          >
            <img src={share.iconSrc} alt={`${share.type}-icon`} />
          </button>
        ))}
      </div>
    </>
  );
};

export default ShareButtons;
