import React from "react";
import { calcSceneHeight } from "../functions";

const SceneFrame = ({ children, isOverWriteContents = false }) => {
  const height = calcSceneHeight(window.innerWidth);
  return (
    <div className="scene-background" style={{ height: `${height}px` }}>
      <div className="scene-frame" style={{ height: `${height}px` }}>
        {isOverWriteContents ? (
          children
        ) : (
          <div className="scene-frame-contents">{children}</div>
        )}
      </div>
    </div>
  );
};

export default SceneFrame;
