import React, { useState } from "react";
import { withRouter } from "react-router-dom";

const NumaButton = withRouter(({ history, to, children }) => {
  const [selected, setSelected] = useState(false);
  const onPush = () => {
    setSelected(true);
    setTimeout(() => {
      history.push(to);
    }, 250);
  };
  return (
    <button
      style={{ width: "100%" }}
      className={[selected ? "selected" : ""].join(" ")}
      onClick={() => onPush()}
    >
      {children}
    </button>
  );
});

export default NumaButton;
