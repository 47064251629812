import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import './App.css';
import Home from './pages/Home';
import Questions from './pages/Questions';
import Result from './pages/Result';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { createHashHistory } from 'history';
import { isProduction } from "./constants";
import { APP_MAX_WIDTH } from './functions';

const history = createHashHistory();

if (isProduction) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  
  history.listen(({ pathname }) => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  })
}

export default class App extends Component {
  componentDidMount() {
    if (isProduction) {
      ReactGA.set({ page: history.location.pathname });
      ReactGA.pageview(history.location.pathname);
    }
  }

  render() {
    return (
      <HashRouter history={history}>
        <Header />
        <ScrollToTop />
        <div className="container" style={{ maxWidth: APP_MAX_WIDTH }}>    
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/questions' component={Questions} />
            <Route path='/result/:id' component={Result} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </HashRouter>
    );
  }
}