
import tori1 from './assets/toris/001_mabataki.gif';
import tori2 from './assets/toris/002_naku.gif';
import tori3 from './assets/toris/003_tsuburu.gif';
import tori4 from './assets/toris/004_warau.gif';
import tori5 from './assets/toris/005_shikameru.gif';
import tori7 from './assets/toris/007_kyoro.gif';
import tori8 from './assets/toris/008_doyagao.gif';
import tori9 from './assets/toris/009_unazuku.gif';
import tori10 from './assets/toris/010_fueru.gif';
import tori12 from './assets/toris/012_magao.gif';
import tori13 from './assets/toris/013_suwaru.gif';
import tori14 from './assets/toris/014_nobi.gif';
import tori15 from './assets/toris/015_shinsyuku.gif';
import tori16 from './assets/toris/016_hatena.gif';
import tori17 from './assets/toris/017_gatagata.gif';
import tori18 from './assets/toris/018_neru.gif';
import tori19 from './assets/toris/019_tatsu.gif';
import tori20 from './assets/toris/020_sayu.gif';

export const isProduction = (process.env.NODE_ENV === 'production');

export const TORI_STORE = [
  { id: 1, src: tori1 },
  { id: 2, src: tori2 },
  { id: 3, src: tori3 },
  { id: 4, src: tori4 },
  { id: 5, src: tori5 },
  // { id: 6, src: tori6 },
  { id: 7, src: tori7 },
  { id: 8, src: tori8 },
  { id: 9, src: tori9 },
  { id: 10, src: tori10 },
  // { id: 11, src: tori11 },
  { id: 12, src: tori12 },
  { id: 13, src: tori13 },
  { id: 14, src: tori14 },
  { id: 15, src: tori15 },
  { id: 16, src: tori16 },
  { id: 17, src: tori17 },
  { id: 18, src: tori18 },
  { id: 19, src: tori19 },
  { id: 20, src: tori20 },
]
