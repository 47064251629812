import React from "react";
import SceneFrame from "../components/SceneFrame";
import NumaButton from "../components/NumaButton";
import tatsu from "../assets/toris/019_tatsu.gif";
import ShareButtons from "../components/ShareButtons";

const Home = () => (
  <>
    <SceneFrame>
      <div className="flex title">
        <h1>現実逃避</h1>
        <h1>映画診断</h1>
      </div>
    </SceneFrame>
    <SceneFrame>
      <div className="flex">
        <div>
          <img style={{ width: "50%" }} src={tatsu} alt="tatu-tori"></img>
        </div>
        <div>
          <p>
            かわいい小鳥といっしょに
            <br />
            この世界の出口を探そう
          </p>
        </div>
      </div>
    </SceneFrame>
    <SceneFrame>
      <div className="index-link flex">
        <div style={{ margin: "0 10%", width: "80%" }}>
          <NumaButton style={{ width: "80%" }} to="/questions">
            出口を探す
          </NumaButton>
        </div>
        <p>所要時間　30秒〜3分</p>
        <ShareButtons
          shareUrl={window.location.href}
          shareText={"現実逃避映画診断"}
        />
      </div>
    </SceneFrame>
  </>
);

export default Home;
