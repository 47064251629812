import React from "react";

const ProgressBar = ({ height = 10, val, max }) => {
  // 右から左に減っていくから逆転している
  const progress = 100 - (val * 100) / max;
  return (
    <div
      style={{
        height,
        width: "100%",
        borderWidth: "3px",
        borderStyle: "solid",
        borderColor: "#3ACCD9",
        background:
          "linear-gradient(90deg, #36CBDB -9.74%, #D0E795 100%), #593B8A"
      }}
    >
      <div
        style={{
          height,
          marginLeft: `${100 - progress}%`,
          width: `${progress}%`,
          background: "#F3E5C2"
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
