import React from "react";
import neru from "../assets/toris/018_neru.gif";
import kyoro from "../assets/toris/007_kyoro.gif";
import hatena from "../assets/toris/016_hatena.gif";
import { TORI_STORE } from "../constants";

const ToriBlock = ({ id, sleeping = false, children }) => {
  const { src } = TORI_STORE.find(tori => tori.id === id) || TORI_STORE[0];
  return (
    <div className="tori-block">
      {children}
      <div>
        {sleeping ? (
          renderRandomTori()
        ) : (
          <img src={src} alt="tori" className="tori-img"></img>
        )}
      </div>
    </div>
  );
};

const renderRandomTori = () => {
  const src = shuffle([neru, kyoro, hatena])[0];
  return <img src={src} alt="tori" className="tori-img"></img>;
};

const shuffle = array => {
  for (var i = array.length - 1; i > 0; i--) {
    const r = Math.floor(Math.random() * (i + 1));
    const tmp = array[i];
    array[i] = array[r];
    array[r] = tmp;
  }
  return array;
};

export default ToriBlock;
