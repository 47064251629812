import React from "react";
import Cinema from "../components/Cinema";
import { useParams } from "react-router-dom";

const withinCinemaId = id => {
  const num = Number(id);
  return num !== NaN && 1 <= num && num <= 36;
};

const Result = () => {
  const { id } = useParams();
  // NOTE: id=14 マッドマックス
  const num = withinCinemaId(id) ? Number(id) : 14;
  return <Cinema id={Number(num)} />;
};

export default Result;
