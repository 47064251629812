import React from "react";
import { calcSceneHeight } from "../functions";

const ResultTori = () => {
  const height = calcSceneHeight(window.innerWidth, { h: 144, w: 375 });
  const toriStyle = {
    width: "100%",
    height: height,
    marginTop: height * -1
  };
  return (
    <div className="result-tori" style={toriStyle}>
      <div className="fukidashi">
        <p>このサイトは</p>
        <p>フローチャート診断サービス</p>
        <p>numaflowの予告編です</p>
        <p>
          詳しくは
          <a
            href="https://numacinema.xemono.life/lp"
            target="_blank"
            rel="noopener"
          >
            こちら
          </a>
          からどうぞ
        </p>
      </div>
    </div>
  );
};

export default ResultTori;
