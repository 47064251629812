import React from "react";
import SceneFrame from "../components/SceneFrame";
import { Element } from "react-scroll";
import CINEMA_STORE from "../assets/cinema.json";
import CinemaPreview from "./CinemaPreview";
import NumaButton from "../components/NumaButton";
import ResultTori from "../components/ResultTori";
import ShareButtons from "../components/ShareButtons";

const Cinema = ({ id }) => {
  const cinema = CINEMA_STORE.find(cinema => cinema.id === id);
  const director = `監督：${cinema.director}`;
  const releaseDate = `${cinema.releasedDate}`;
  const teitterHashTag = "#現実逃避映画診断 #numaflow";
  const shareUrl = `${process.env.REACT_APP_BASE_URL}/result/${id}`;
  const copyText = [cinema.shareDescription, teitterHashTag, shareUrl].join(
    "\n"
  );
  return (
    <Element name="cinema">
      <SceneFrame>
        <div className="flex cinema-result">
          <div>
            <span>あなたには…</span>
          </div>
          <div className="full-content">
            <div>
              {cinema.title.split("<br />").map(title => (
                <h2>{title}</h2>
              ))}
            </div>
          </div>
          <div>
            <span>{director}</span>
            <br />
            <span>{releaseDate}</span>
          </div>
        </div>
      </SceneFrame>
      <SceneFrame>
        <div className="flex result-image">
          <CinemaPreview
            youtubeId={cinema.youtubeId}
            amazonLink={cinema.amazonLink}
            amazonImageLink={cinema.amazonImageLink}
          />
        </div>
      </SceneFrame>
      <SceneFrame>
        <div className="flex" style={{ width: "95%", margin: "auto" }}>
          <p className="result-description">{cinema.description}</p>
        </div>
      </SceneFrame>
      <SceneFrame>
        <div className="flex result-share">
          <p>診断結果をシェア</p>
          <ShareButtons
            shareUrl={shareUrl}
            shareText={cinema.shareDescription}
          />
          <div className="result-copy">
            <p>コピペ用</p>
            <textarea rows="4" onFocus={event => event.target.select()}>
              {copyText}
            </textarea>
          </div>
        </div>
      </SceneFrame>
      <SceneFrame isOverWriteContents={true}>
        <div className="scene-frame-contents">
          <div style={{ paddingTop: "10%" }}>
            <div style={{ width: "80%", marginLeft: "10%" }}>
              <NumaButton to="/">最初から始める</NumaButton>
            </div>
          </div>
        </div>
        <ResultTori />
      </SceneFrame>
    </Element>
  );
};

export default Cinema;
