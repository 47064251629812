import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import SceneFrame from "../components/SceneFrame";
import ProgressBar from "../components/ProgressBar";
import { Element, scroller, animateScroll } from "react-scroll";
import ToriBlock from "../components/ToriBlock";
import Cinema from "../components/Cinema";
import QUESTION_STORE from "../assets/questions.json";
import { calcDepth } from "../functions";
import { isProduction } from "../constants";

const Questions = () => {
  const [questions, setQuestions] = useState([
    { question: QUESTION_STORE[0], answerIndex: undefined }
  ]);
  const [cinemaId, setCinemaId] = useState(undefined);
  const [sleeping, setSleeping] = useState(undefined);
  let setTimeoutId;
  useEffect(() => {
    const options = { smooth: true };
    if (!!cinemaId) {
      scroller.scrollTo("cinema", options);
    } else if (questions.length > 1 && !sleeping) {
      // scroller.scrollTo(`question-${questions.length - 1}`, options);
      animateScroll.scrollToBottom(options);
    }

    setTimeoutId = setTimeout(() => {
      // 映画到達までしていたら変えない
      if (!cinemaId) {
        setSleeping(true);
      }
    }, 5000);
  });

  const maxQuestionCount =
    questions.length -
    1 +
    calcDepth(questions[questions.length - 1].question, QUESTION_STORE);

  // TODO: useCallback
  // TODO: ちゃんとした処理に
  // 汚い
  const onClickByAnswer = (answer, questionIndex, answerIndex) => {
    const data = questions.slice(0, questionIndex + 1);
    data[questionIndex].answerIndex = answerIndex;
    const next = findNext(answer);
    if (!!next) {
      data.push({ question: next });
      setCinemaId(undefined);
    } else {
      setCinemaId(answer.resultCinemaId);
      if (isProduction) {
        const questionIds = data.map(({ question }) => question.id).join(",");
        ReactGA.event({
          category: "found result cinema",
          action: `cinema: ${answer.resultCinemaId}`,
          label: `passed questions: ${questionIds}`,
          value: 1
        });
      }
    }
    setQuestions(data);
    clearTimeout(setTimeoutId);
    setSleeping(false);
  };
  return (
    <>
      {/* これusereducerに移行したいな
        filterはちゃんとやめる
      */}
      {questions.map(({ question, answerIndex }, index) => (
        <Element name={`question-${index}`}>
          <SceneFrame key={`${index}`}>
            <ToriBlock
              sleeping={isSleeping(index + 1 === questions.length, sleeping)}
              id={question.toriFaceId}
            >
              <div className="question-block">
                <h1 style={{ marginBottom: "5px" }}>Q{index + 1}</h1>
                <ProgressBar max={maxQuestionCount} val={index + 1} />
              </div>
            </ToriBlock>
          </SceneFrame>
          <SceneFrame>
            <div className="flex" style={{ width: "80%", margin: "auto" }}>
              <p className="question-label">{question.label}</p>
            </div>
          </SceneFrame>
          <SceneFrame>
            <div className="flex question-buttions">
              {question.answerList.map((answer, ansIdx) => (
                <>
                  <button
                    className={[
                      "answer-button",
                      answerIndex === ansIdx ? "selected" : ""
                    ].join(" ")}
                    onClick={() => onClickByAnswer(answer, index, ansIdx)}
                  >
                    {answer.label}
                  </button>
                </>
              ))}
            </div>
          </SceneFrame>
        </Element>
      ))}
      {!!cinemaId ? <Cinema id={cinemaId} /> : null}
    </>
  );
};

const isSleeping = (isLast, sleeping) => {
  return isLast && sleeping;
};

const findNext = answer =>
  answer.nextQuestionId
    ? QUESTION_STORE.find(question => question.id === answer.nextQuestionId)
    : null;

export default Questions;
