import React from "react";
import Youtube from "react-youtube";
import { calcSceneHeight, calcSceneWidth } from "../functions";

const _onReady = event => {
  // NOTE: 一部だけ勝手に始まるものがあるから制御入れるのやめた
  // event.target.stopVideo();
};

const renderYoutube = ({ id }) => {
  const width = calcSceneWidth(window.innerWidth) * 0.69;
  const height = calcSceneHeight(width);
  const opts = {
    height,
    width,
    playerVars: {
      autoplay: 1
    }
  };
  return <Youtube videoId={id} opts={opts} onReady={_onReady} />;
};

const renderAamazon = ({ link, imageLink }) => {
  return (
    <>
      <a href={link} target="_brank" rel="noopener">
        <img src={imageLink} alt={imageLink} />
      </a>
      <p>※画像はamazonより</p>
    </>
  );
};

const CinemaPreview = ({ youtubeId, amazonLink, amazonImageLink }) => {
  return youtubeId
    ? renderYoutube({ id: youtubeId })
    : renderAamazon({ link: amazonLink, imageLink: amazonImageLink });
};

export default CinemaPreview;
