export const APP_MAX_WIDTH = 375;

/**
 * 画像サイズ　375 × 230
 * 46 : 75
 * https://tools.m-bsys.com/calculators/ratio.php
 */
export const calcSceneHeight = (width, ratio = {h: 46, w: 75}) => {
  const w = calcSceneWidth(width);
  return w !== 0 ? (ratio.h * calcSceneWidth(width)) / ratio.w  : 0;
}

export const calcSceneWidth = (width) => (
  (width > APP_MAX_WIDTH ? APP_MAX_WIDTH : width)
)

export const calcDepth = (question, store) => {
  const childDepths = question.answerList.map(answer => {
    if (!answer.nextQuestionId) {
      return 0;
    }
    const nextQuestion = store.find(s => s.id === answer.nextQuestionId);
    return calcDepth(nextQuestion, store);
  });
  // 現在の階層も含めるので +1している
  return Math.max(...childDepths) + 1;
}
